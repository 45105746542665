<template>
  <div class="flex flex-col">
    <FullWaveFormVisualizer
      ref="wf"
      :file-id="fileId"
      :cue="cue"
      :lines="FullChat"
      @previous-fragment="previousFragment"
      @next-fragment="nextFragment"
    />
    <div v-if="state !== 0" class="mt-12">
      <div>
        <div class="flex flex-col">
          <div class="flex w-full justify-center">
            <FillingFormFullAudio
              v-show="!payedAudio"
              ref="filling-form"
              @save:chat="saveChat"
              :lang="lang"
              :line-index="startAudio"
              :lines="FullChat"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullWaveFormVisualizer from "@/views/files/shared/components/FullWaveFormVisualizer.vue";
import { getTranscript, saveTranscript } from "@/api/files";
import FillingFormFullAudio from "@/views/files/shared/components/FillingFormFullAudio.vue";

export default {
  components: {
    FillingFormFullAudio,
    FullWaveFormVisualizer,
  },

  props: {
    fileId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
    },
    lang: {
      type: String,
      required: true,
    },
    savedLines: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      startAudio: 1,
      cue: 0,
      FullChat: [],
    };
  },

  computed: {
    state() {
      return parseInt(this.$route.params.state);
    },
    user() {
      return this.$store.state.user.user;
    },
    payedAudio() {
      return this.state === 5;
    },
    allowedSymbolsRegex() {
      return /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя\s\-.*?+]+$/u;
    },
    filteredFullChat() {
      if (!Array.isArray(this.FullChat)) return []; // Ensure FullChat is an array

      const invalidIndex = this.FullChat.findIndex((item) => {
        // Ensure item.text is a string
        if (typeof item.text !== "string") return true; // Treat non-strings as invalid

        // Treat empty strings as valid or skip them
        if (item.text.trim() === "") return false;

        // Check against the regex
        return !this.allowedSymbolsRegex.test(item.text);
      });

      // Slice the array up to (but not including) the invalid index
      return invalidIndex >= 0
        ? this.FullChat.slice(0, invalidIndex)
        : this.FullChat;
    },
  },
  watch: {
    FullChat: {
      immediate: true,
      handler(newLines) {
        if (newLines && newLines.length > 0) {
          this.cue = newLines[0].start;
        }
      },
    },
  },
  async mounted() {
    await this.getFullChat();
    if (this.FullChat[0] && this.FullChat[0].start) {
      this.cue = this.FullChat[0].start || 0;
    }
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    async getFullChat() {
      try {
        const transcript = await getTranscript(this.fileId);
        const all = Array.isArray(transcript.all) ? transcript.all : [];
        const all_utterances = transcript.all_utterances;
        const regex = this.allowedSymbolsRegex;

        const invalidIndex = all_utterances.findIndex(
          (item) => !regex.test(item.text)
        );

        // If no invalid index is found, return the original array
        if (!all.length > 0) {
          // No invalid symbols detected, return the full array as-is
          this.FullChat = all_utterances;
        } else {
          // Slice only from invalid index to the end
          const slicedUtterances = all_utterances.slice(invalidIndex);
          this.FullChat = [...all, ...slicedUtterances];
        }
      } catch (error) {
        console.error(error);
      }
    },
    saveChat(text, skip, index) {
      // Update only the `text` property of the object at the given index
      const i = index - 1;
      this.FullChat[i].text = text || "";
      this.FullChat[i].skip = skip;

      // Save changes if needed
      this.save();
    },
    previousFragment(index) {
      this.startAudio = index;
      const i = index - 1;
      this.editIntervalText(i);
      this.cue = this.FullChat[index - 1].start;
    },
    nextFragment(index) {
      this.startAudio = index;
      const i = index - 1;
      this.editIntervalText(i);
      this.cue = this.FullChat[index - 1].start;
    },

    async save() {
      await saveTranscript(this.fileId, {
        lines: this.filteredFullChat,
        channel: "all",
      });
      this.$emit("save:changedLines", {
        payload: this.filteredFullChat,
      });
    },

    editIntervalText(i) {
      if (this.$refs["filling-form"]) {
        this.$refs["filling-form"].insertFormData({
          ...this.FullChat[i],
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style></style>
