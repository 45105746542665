<template>
  <div class="flex items-start flex-col w-full">
    <div class="flex flex-wrap w-full items-center gap-2.5">
      <div>
        <el-tooltip content="Shift + V" :show-after="300" placement="top">
          <el-checkbox border v-model="form.skip">Пропустить</el-checkbox>
        </el-tooltip>
      </div>
      <el-tooltip
        content="Сохранить (Ctrl + S)"
        placement="top"
        :show-after="300"
        :disabled="notAllowed"
      >
        <el-button :disabled="notAllowed" type="primary" plain @click="saveChat"
          >Сохранить</el-button
        ></el-tooltip
      >
    </div>
    <div class="w-full mt-2">
      <el-input
        ref="transcribe-input"
        id="transcribe-input"
        class="mt-2 h-12"
        type="text"
        :disabled="form.skip"
        v-model="form.text"
        @input="filterAndValidateInput"
        @keydown="handleKeydown"
      />
      <div
        v-if="invalidWords && invalidWords.length > 0"
        class="text-xs text-red-500 mt-1 break-words"
      >
        {{ invalidWords }}<span class="ml-0.5">не найдены в словаре.</span>
      </div>
      <span v-if="notAllowed" class="text-xs text-red-500">
        Недопустимые символы</span
      >
    </div>
  </div>
</template>
<script>
import apiClient from "@/api/api-client";
import store from "@/store";

export default {
  name: "FillingFormFullAudio",
  props: {
    lang: {
      type: String,
      default: "kz",
    },
    lines: {
      type: Array,
    },
    lineIndex: {
      type: Number,
    },
  },
  data() {
    return {
      form: {
        text: "",
        skip: false,
      },
      invalidWords: [],
      debounce: null,
      isButtonDisabled: true,
      editingLineIndex: null,
    };
  },
  computed: {
    notAllowed() {
      if (this.form.text === "") {
        return false;
      }
      const allowedSymbolsRegex =
        /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя\s\-.*?+]+$/u;
      return !allowedSymbolsRegex.test(this.form.text);
    },
  },
  watch: {
    lines: {
      immediate: true,
      handler(newLines) {
        if (newLines && newLines.length > 0) {
          this.form.text = newLines[0].text || "";
        }
      },
    },
  },
  mounted() {
    this.$refs["transcribe-input"].focus();
    if (this.lines && this.lines.length > 0 && this.lines[0]) {
      this.form.text = this.lines[0].text || ""; // Safely set form.text to the first text
    }
    window.addEventListener("keydown", this.handleSave);
    window.addEventListener("keydown", this.handleSkip);
    console.log(this.notAllowed);
    if (!this.notAllowed) {
      this.saveInterval = setInterval(() => {
        if (!this.notAllowed) {
          store.dispatch("notify/add", {
            type: "info",
            message: "Автосохранение",
            size: "mini",
          });
        }
        this.saveChat();
      }, 30000);
    }
  },
  beforeUnmount() {
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    window.removeEventListener("keydown", this.handleSave);
    window.removeEventListener("keydown", this.handleSkip);
  },
  methods: {
    filterAndValidateInput() {
      // Allowed symbols: Cyrillic letters, space, hyphen, period, question mark, plus, and asterisk
      const allowedSymbolsRegex =
        /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя\s\-.*?+]+$/u;

      // Filter out invalid characters in the input
      this.form.text = this.form.text
        .split("") // Split the text into individual characters
        .filter((char) => allowedSymbolsRegex.test(char)) // Only keep allowed characters
        .join(""); // Join the characters back into a string

      // Call validateText() to check words against the API only when a space or punctuation is entered
      this.validateText();
    },

    // This method validates the text and checks for invalid words
    async validateText() {
      const text = this.form.text;

      // Validate text for parentheses, allowed characters, and empty symbols

      // Set error messages and button status based on validation

      // Clear previous debounce if it exists
      if (this.debounce) {
        clearTimeout(this.debounce);
      }

      // Check if the last character is a word boundary (space or punctuation)
      const lastChar = text.slice(-1);
      if (/\s|\p{P}/u.test(lastChar)) {
        // Only trigger API call if last character is space or punctuation
        this.debounce = setTimeout(async () => {
          const words = text.split(/\s+/).filter((word) => word.length > 0); // Split input into words

          const validationSymbols =
            /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя]*$/u;

          // Clear previous invalid words before checking again
          this.invalidWords = [];

          // Process each word independently and validate with API
          for (const word of words) {
            if (validationSymbols.test(word)) {
              try {
                const response = await apiClient.get(`/word`, {
                  params: { text: word },
                });
                // Add to invalid words list if word is not valid
                if (!response.data._success) {
                  this.invalidWords.push(word);
                }
              } catch (error) {
                console.error(error);
                this.invalidWords.push(word);
              }
            }
          }
        }, 300);
      } else {
        // Clear invalid words if there's no space or punctuation (user is still typing a word)
        this.invalidWords = [];
      }
    },
    insertFormData({ text, skip }) {
      this.form = { text, skip };
    },
    saveChat() {
      if (!this.notAllowed) {
        this.$emit("save:chat", this.form.text, this.form.skip, this.lineIndex);
      }
    },
    handleKeydown(event) {
      if (!event.shiftKey && event.keyCode === 13 && !this.notAllowed) {
        event.preventDefault();
        this.saveChat();
      }
    },
    handleSave(event) {
      // Check if both Control and S keys are pressed
      if (event.ctrlKey && event.code === "KeyS") {
        event.preventDefault();
        this.saveChat();
      }
    },
    handleSkip(event) {
      // Check if Shift and V keys are pressed
      if (event.shiftKey && event.code === "KeyV") {
        event.preventDefault();
        this.form.skip = !this.form.skip;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
